@import './colors';
.naButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: DMSans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    border: 2px solid;
    height: 28px;
    border-radius: 32px;
    cursor: pointer;
    &.primary {
        min-width: 120px;
    }
    &.small {
        min-width: 28px;
    }
}

.dark {
    .naButton.disabled {
        background-color: transparent !important;
        border-color: $dusk60 !important;
        color: $dusk60 !important;
    }
    .naButton.normal.off {
        border-color: $greenGrass;
        color: $greenGrass;
    }
    .naButton.normal.on {
        background-color: $greenGrass;
        border-color: $greenGrass;
        color: #ffffff;
    }
    .naButton.normal.off:hover,
    .naButton.normal.on:hover {
        background-color: $greenGrass;
        border-color: #ffffff;
        color: #ffffff;
    }
    .naButton.abnormal.off {
        border-color: $red;
        color: $red;
    }
    .naButton.abnormal.on {
        background-color: $red;
        border-color: $red;
        color: #ffffff;
    }
    .naButton.abnormal.on:hover,
    .naButton.abnormal.off:hover {
        background-color: $red;
        color: #ffffff;
        border-color: #ffffff;
    }
}
