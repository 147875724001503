@import './colors';

.scrollable {
    overflow-y: auto;
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}
.dark {
    .scrollable::-webkit-scrollbar-track {
        background-color: $calmDigitalGreen60;
    }
    .scrollable::-webkit-scrollbar-thumb {
        background-color: $dusk60;
    }
}
.light {
    .scrollable::-webkit-scrollbar-track {
        background-color: $palePink;
    }
    .scrollable::-webkit-scrollbar-thumb {
        background-color: $dusk60;
    }
}
