@import '../css/colors';

button {
    text-transform: capitalize;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    min-width: 120px;
    padding: 0px 10px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &[disabled] {
        pointer-events: none;
    }
}
.normalButton {
    min-height: 40px;
    max-height: 40px;
}
.smallButton {
    min-height: 32px;
    max-height: 32px;
}
.primaryButton {
    border: none;
}
.dark {
    .primaryButton {
        color: $calmDigitalGreen20;
        background-color: $dusk;
        &:hover,
        &:focus {
            border: solid;
            border-color: $calmDigitalGreen40;
            background-color: $dusk80;
            color: $calmDigitalGreen20;
            padding: 0px 7px;
        }
        &[disabled] {
            background-color: $calmDigitalGreen;
            color: $dusk;
        }
    }

    .secondaryButton {
        border-color: $calmDigitalGreen40;
        color: $calmDigitalGreen40;
        &:hover,
        &:focus {
            border-color: $salmon;
            color: $salmon;
        }
        &[disabled] {
            border-color: $dusk60;
            color: $dusk60;
        }
    }
}
.light {
    .primaryButton {
        color: white;
        background-color: $calmGreen;
        &:hover,
        &:focus {
            color: white;
            background-color: $calmDigitalGreen;
        }
        &[disabled] {
            color: $calmDigitalGreen40;
        }
    }

    .secondaryButton {
        background-color: $palePink;
        border-color: $calmDigitalGreen;
        color: $dusk;
        &:hover,
        &:focus {
            background-color: $calmDigitalGreen20;
            border-color: $calmDigitalGreen;
            color: $dusk;
        }
        &[disabled] {
            background-color: $calmDigitalGreen40;
            color: #fff;
        }
    }
}
