@import './colors';
// <label class="checkbox normal/secondary">
//      <input type="checkbox" />
//      <span></span>
//      <h5>{{ 'qwe' | translate }}</h5>
// </label>

/* The radiobutton */
.checkbox {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 140px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    &:has(input:disabled) {
        cursor: default;
    }
    input {
        display: none;
        &:checked ~ span:after {
            display: block;
        }
    }
    span {
        position: relative;
        min-height: 22px;
        min-width: 22px;
        border: 1px solid;
    }
    span:after {
        content: '';
        display: none;
        position: absolute;
        left: 3px;
        bottom: 9px;
        height: 7px;
        width: 16px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        box-sizing: border-box;
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Safari prior 9.0 */
        transform: rotate(-45deg); /* Standard syntax */
    }
    & > :last-child {
        margin-left: 10px;
    }
}

.dark {
    .checkbox.normal {
        span {
            border-color: $calmDigitalGreen40;
            background-color: transparent;
        }
        &:hover input:not(:disabled) ~ span {
            background-color: rgba(255, 255, 255, 0.1);
        }
        input {
            &:checked ~ span::after {
                border-color: $calmDigitalGreen60;
            }
            &:disabled {
                ~ span {
                    border-color: $dusk60;
                }
                ~ span::after {
                    border-color: $dusk60;
                }
                ~ span ~ * {
                    color: $dusk60;
                }
            }
        }
    }

    .checkbox.secondary {
        span {
            border-color: $dusk;
            background-color: $calmDigitalGreen40;
        }
        &:hover input:not(:disabled) ~ span {
            background-color: $calmDigitalGreen20;
        }
        input:checked ~ span:after {
            color: $dusk;
        }
    }
}
