@import './colors';
@import '../fonts/aicos/style.css';

* {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: DMSans;
    letter-spacing: 0.5px;
    &:focus {
        outline: none;
    }
}

html,
body {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    font-size: 13px;
}

h2 {
    font-family: DMSans;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 43px;
    // pointer-events: none;
}
h3 {
    font-family: DMSans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    // pointer-events: none;
}
h4 {
    font-family: DMSans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    // pointer-events: none;
}
h5 {
    font-family: DMSans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 175% */
    letter-spacing: 0.12em;
    text-transform: uppercase;
    // pointer-events: none;
}
h6 {
    font-family: DMSans;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}
p {
    font-family: DMSans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    // pointer-events: none;
}
a {
    font-family: DMSans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    cursor: pointer;
}
small {
    font-family: DMSans;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.examColumn {
    min-width: calc(75vw / 9);
    max-width: calc(75vw / 9);
}
.lastItem {
    flex-grow: 1;
    max-width: initial;
    min-width: initial;
    width: initial;
}
.dark {
    .evenHighlited:nth-child(even),
    .oddHighlited:nth-child(odd) {
        background-color: $dusk60;
    }
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    small {
        color: $calmDigitalGreen20;
    }
    a {
        color: $calmDigitalGreen60;
        &:hover {
            color: $salmon;
        }
    }
    i[class*='aico-'] {
        color: $calmDigitalGreen20;
    }
    i.aico-disabled {
        color: $dusk60;
    }
}
.light {
    .evenHighlited:nth-child(even),
    .oddHighlited:nth-child(odd) {
        background-color: $calmDigitalGreen20;
    }
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    small {
        color: $calmGreen;
    }
    a {
        color: $salmonDigital;
        &:hover {
            color: $salmonPinkDigital;
        }
    }
    i[class*='aico-'] {
        color: $dusk;
    }
    i.aico-disabled {
        color: $calmDigitalGreen40;
    }
}

@font-face {
    font-family: DMSans;
    src: url(../fonts/DMSans/DMSans-Regular.ttf);
}
