@import './colors';

// simple textarea
// <textarea class="normalTextarea" type="text" />
//
// textarea with label
// <div class="textareaWrapper">
//     <label>Q-w-e</label>
//     <textarea class="normalTextarea"><textarea>
// </div>

.textareaWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
        font-family: DMSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
}
textarea {
    border: 2px solid;
    box-sizing: border-box;
    font-family: DMSans;
    font-size: 14px;
    padding: 0px 12px;
    width: 100%;
    &::placeholder {
        font-family: DMSans;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
    }
}
.smallTextarea {
    min-height: 92px;
}

.normalTextarea {
    min-height: 108px;
}
.dark {
    .textareaWrapper {
        label {
            color: $calmDigitalGreen20;
        }
    }
    textarea {
        background-color: $calmDigitalGreen40;
        border-color: $calmDigitalGreen80;
        color: $dusk;
        &::placeholder {
            color: $calmDigitalGreen;
        }
        &:focus {
            border-color: $calmDigitalGreen20;
        }
        &:disabled {
            background: $calmDigitalGreen60;
            border: $calmDigitalGreen80;
        }
    }
}
.light {
    .textareaWrapper {
        label {
            color: $calmGreen;
        }
    }
    textarea {
        background-color: #ffffff;
        border-color: $calmDigitalGreen40;
        color: $calmGreen;
        &::placeholder {
            color: $calmDigitalGreen;
        }
        &:focus {
            border-color: $calmDigitalGreen20;
        }
        &:disabled {
            background: $calmDigitalGreen20;
            border: $calmDigitalGreen40;
        }
    }
}
