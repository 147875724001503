$calmDigitalGreen: #4f6c68;
$calmDigitalGreen80: #728986;
$calmDigitalGreen60: #95a7a4;
$calmDigitalGreen40: #b9c4c3;
$calmDigitalGreen20: #dce2e1;

$dusk: #03272b;
$dusk80: #355255;
$dusk60: #687d80;
$dusk40: #9aa9aa;
$dusk20: #cdd4d5;

$calmGreen: #395955;
$palePink: #fafafa;

$salmon: #f5b9a9;
$salmonDigital: #f2ae9c;
$salmonPinkDigital: #f1c8c8;

$greenGrass: #81c53d;
$red: #eb5757;
$blue: #2d9cdb;
