@import './colors';
// <label class="radiobutton type1" *ngFor="let value of radio">
//     <input type="radio" formControlName="type" [value]="value" />
//     <span></span>
//     <h5>{{ value | translate }}</h5>
// </label>

/* The radiobutton */
.radiobutton {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 140px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        display: none;
    }
    span {
        position: relative;
        height: 20px;
        min-width: 20px;
        border-radius: 50%;
        border: 2px solid;
    }
    span:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: none;
    }
    input:checked ~ span:after {
        display: block;
    }
    h5 {
        margin-left: 10px;
    }
}

.dark {
    .type1 {
        span {
            border-color: $calmDigitalGreen40;
            background-color: $calmDigitalGreen40;
        }
        &:hover input ~ span {
            background-color: $calmDigitalGreen20;
        }
        input:checked ~ span {
            background-color: $calmDigitalGreen40;
        }
        span:after {
            background: $dusk;
        }
    }

    .type2 {
        span {
            border-color: $calmDigitalGreen40;
            background-color: transparent;
        }
        &:hover input ~ span {
            background-color: rgba(255, 255, 255, 0.1);
        }
        span:after {
            background: $calmDigitalGreen40;
        }
    }
}
