@import './colors';

// simple input
// <input class="normalInput" type="text" />
//
// input with label
// <div class="inputWrapper">
//     <label>Q-w-e</label>
//     <input class="normalInput" type="text" />
// </div>

.inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
        font-family: DMSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
}
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='tel'] {
    border: 2px solid;
    box-sizing: border-box;
    font-family: DMSans;
    font-size: 14px;
    padding: 0px 12px;
    width: 100%;
    &::placeholder {
        font-family: DMSans;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
    }
    &:disabled {
        cursor: not-allowed;
    }
}
.normalInput {
    min-height: 40px;
}
.smallInput {
    min-height: 32px;
}
.dark {
    .inputWrapper {
        label {
            color: $calmDigitalGreen20;
        }
    }
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'],
    input[type='tel'] {
        background-color: $calmDigitalGreen40;
        border-color: $calmDigitalGreen80;
        color: $dusk;
        &::placeholder {
            color: $calmDigitalGreen;
        }
        &:focus {
            border-color: $calmDigitalGreen20;
        }
        &:disabled {
            background: $calmDigitalGreen60;
            border: $calmDigitalGreen80;
        }
    }
}
.light {
    .inputWrapper {
        label {
            color: $calmGreen;
        }
    }
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'],
    input[type='tel'] {
        background-color: #ffffff;
        border-color: $calmDigitalGreen40;
        color: $calmGreen;
        &::placeholder {
            color: $calmDigitalGreen;
        }
        &:focus {
            border-color: $calmDigitalGreen20;
        }
        &:disabled {
            background: $calmDigitalGreen20;
            border: $calmDigitalGreen40;
        }
    }
}
