@import './colors';

.toggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 140px;
    padding: 0px 10px;
    border-radius: 16px;
    font-family: DMSans;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid;
}

.dark {
    .toggleButton {
        border-color: $calmDigitalGreen40;
        color: $calmDigitalGreen40;
        &:hover {
            background-color: $calmDigitalGreen80;
        }
        &.active {
            background-color: $calmDigitalGreen40;
            color: $dusk;
        }
        &.disabled {
            border-color: $dusk60;
            color: $dusk60;
        }
    }
}
